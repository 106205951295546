<template>
  <tr v-if="mode === 'create' || mode === 'edit'">
    <td class="number"><input type="text" placeholder="Optional" v-model="invoice.details.invoiceNo"></td>
    <td class="client" :class="{ tilted: !invoice.client.name }">{{ invoice.client.name || 'Unknown' }}</td>
    <td class="booking">
      <InputSearch :initValue="invoice.booking.details?.regNo" @selected-booking="handleBooking" />
    </td>
    <td class="date"><input type="date" :value="invoice.details.issueDate ? new Date(invoice.details.issueDate).toISOString().substring(0, 10) : null" @change="(e) => handleDate('issue', e.target.value)" required></td>
    <td class="date"><input type="date" :value="invoice.details.dueDate ? new Date(invoice.details.dueDate).toISOString().substring(0, 10) : null" @change="(e) => handleDate('due', e.target.value)" required></td>
    <td>
      <input type="number" v-model="invoice.prices.discount" min="0" max="100" placeholder="Discount (in %)" required>
      <input type="number" v-model="invoice.prices.tax" min="0" max="100" placeholder="Tax (in %)" required>
    </td>
    <td>
      <input type="checkbox" v-model="invoice.details.isEstimate">
    </td>
    <td class="status">
      <select v-model="invoice.details.status">
        <option value="open">Open</option>
        <option value="sent">Sent</option>
        <option value="paid">Paid</option>
        <option value="partiallyPaid">Partially Paid</option>
        <option value="cancelled">Cancelled</option>
      </select>
    </td>
    <td class="button-area">
      <button v-if="!isLoading && mode === 'edit'" @click="handleSave">Update</button>
      <button v-if="!isLoading && mode === 'edit'" @click="mode = null" class="cancel">Cancel</button>
      <button v-if="!isLoading && mode === 'create'" class="secondary" @click="handleSave">Add</button>
      <button v-else-if="isLoading && mode === 'edit'" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
      <button v-else-if="isLoading && mode === 'create'" class="secondary" disabled><img src="@/assets/images/spinner.svg" alt="Loading..."></button>
    </td>
  </tr>
  <tr v-else @click="handleRoute">
    <td class="number">{{ invoice.details.invoiceNo }}</td>
    <td class="client"><router-link :to="{ name: 'Client', params: { id: invoice.client.id } }">{{ invoice.client.name }}</router-link></td>
    <td class="booking"><router-link :to="{ name: 'Booking', params: { id: invoice.booking.id } }">{{ invoice.booking.details.regNo }}</router-link></td>
    <td class="date">{{ new Date(invoice.details.issueDate).toLocaleDateString() }}</td>
    <td class="date">{{ new Date(invoice.details.dueDate).toLocaleDateString() }}</td>
    <td>£{{ invoice.prices.total.toFixed(2) }}</td>
    <td><input type="checkbox" v-model="invoice.details.isEstimate" disabled></td>
    <td class="status">{{ invoice.details.status.charAt(0).toUpperCase() + invoice.details.status.slice(1) }}</td>
    <td class="button-area">
      <button v-if="!isLoading && !mode" @click="mode = 'edit'">Edit</button>
    </td>
  </tr>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import useFetch from '@/composables/useFetch'
import { Toast } from '@/composables/useSwal'
import toIsoString from '@/composables/getIsoDate'
import InputSearch from '../Booking/InputSearch.vue'

export default {
  props: ['mode', 'invoice'],
  components: { InputSearch },
  setup(props) {
    const router = useRouter()
    const { error, isLoading, fetchData } = useFetch()
    const mode = ref(props.mode)
    const invoice = ((!props.mode || props.mode === 'edit') && props.invoice) 
      ? ref(props.invoice)
      : ref({
          details: {
            invoiceNo: null,
            number: null,
            issueDate: Date.now(),
            dueDate: null,
            status: 'open',  
            isEstimate: true
          },
          booking: {
            id: null,
          },
          client: {
            id: null,
            name: null
          },
          prices: {
            parts: 0,
            labour: 0,
            subtotal: 0,
            total: 0,
            discount: null,
            tax: null
          },
          services: []
        })

    const handleRoute = (e) => {
      if (mode.value == 'edit' || !invoice.value.id || e.target.tagName === 'A') {
        return
      }

      router.push({ name: 'Invoice', params: { id: invoice.value.id } })
    }

    const handleDate = (date, value) => {
      if (date === 'start') {
        invoice.value.details.issueDate = new Date(value).getTime()
      } else if (date === 'due') {
        invoice.value.details.dueDate = new Date(value).getTime()
      }
    }

    const handleBooking = (bookingId, clientId, clientName) => {
      invoice.value.booking.id = bookingId
      invoice.value.client.id = clientId
      invoice.value.client.name = clientName
    }

    const handleSave = async () => {
      if (!invoice.value.booking.id || !invoice.value.client.id) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please choose a Booking for which to create the Invoice'
        })

        return
      }

      if (!invoice.value.details.issueDate || !invoice.value.details.dueDate) {
        Toast.fire({
          icon: 'error',
          title: 'Hey there...',
          text: 'Please add an Issue and a Due Date'
        })

        return
      }

      invoice.value.prices.discount = invoice.value.prices.discount ? invoice.value.prices.discount : 0
      invoice.value.prices.tax = invoice.value.prices.tax ? invoice.value.prices.tax : 0

      if (mode.value !== 'edit') {
        await fetchData('/invoices', 'post', invoice.value)
      } else {
        await fetchData(`/invoices/${invoice.value.id}`, 'patch', invoice.value)
      }

      if (!error.value) {
        Toast.fire({
          icon: 'success',
          title: 'Success!',
          text: mode.value !== 'edit' ? 'Invoice created successfully' : 'Invoice updated successfully'
        })

        if (mode.value === 'edit') {
          mode.value = null
        } else if (mode.value === 'create') {
          invoice.value = {
            details: {
              invoiceNo: null,
              number: null,
              issueDate: Date.now(),
              dueDate: null,
              status: 'open',  
            },
            booking: {
              id: null,
            },
            client: {
              id: null,
              name: null
            },
            prices: {
              parts: 0,
              labour: 0,
              subtotal: 0,
              total: 0,
              discount: null,
              tax: null
            },
            services: []
          }
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'Uh-oh...',
          text: `Error: ${error.value}`
        })
      }
    }

    return { isLoading, mode, invoice, toIsoString, handleRoute, handleDate, handleBooking,
      handleSave }
  }
}
</script>