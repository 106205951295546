<template>
  <div class="select-client" tabindex="0" @focus="showBookingList = true" @focusout="showBookingList = false">
    <div class="search-wrap">
      <input type="text" :value="searchTerm" @input="handleSearchTerm" @change="handleSearchChange" @keydown.enter.prevent="handleSearch" @focus="showBookingList = true" placeholder="Search for Booking">
      <div :class="{ shown: showBookingList }" class="clients-list">
        <div v-if="!isLoading">
          <div v-for="booking in bookings" :key="booking.id" @click="() => handleBooking(booking)" class="client-item text">
            {{ booking.details.regNo }} - ({{ booking.client.name }})
          </div>
        </div>
        <div v-if="!isLoading && bookings !== null && !bookings.length" class="text">No Bookings match searched term</div>
        <div v-if="!isLoading && bookings === null" class="text">Search for Booking using Reg. No.</div>
        <div v-if="!isLoading && error" class="text">{{ error }}</div>
        <div v-if="isLoading" class="text">Searching Bookings...</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import useFirestore from '@/composables/useFirestore'

export default {
  props: ['initValue'],
  emits: ['selected-booking'],
  setup(props, { emit }) {
    const { error, isLoading, getDocuments } = useFirestore()
    const bookings = ref(null)
    const showBookingList = ref(false)
    const searchTerm = ref(props.initValue || null)

    const handleSearchTerm = (e) => {
      searchTerm.value = e.target.value
    }

    const handleSearch = async () => {
      if (searchTerm.value) {
        const bookingsRes = await getDocuments(
          'bookings',
          ['details.regNo', '>=', `${searchTerm.value.toUpperCase()}`],
          ['details.regNo', '<=', `${searchTerm.value.toUpperCase()}`+ '\uf8ff']
        )

        bookings.value = bookingsRes
        
        if (bookings.value && bookings.value.length) {
          showBookingList.value = true
        }
      } else {
        bookings.value = null
      }
    }
    
    const handleBooking = (booking) => {
      emit('selected-booking', booking.id, booking.client.id, booking.client.name)

      showBookingList.value = false
      searchTerm.value = booking.details.regNo
    }
    
    const handleRemove = () => {
      emit('selected-booking', null, null, null)
    }

    return { bookings, showBookingList, searchTerm, error, isLoading, handleSearchTerm,
      handleSearch, handleBooking, handleRemove }
  }
}
</script>