<template>
  <div class="select-wrap">
    <label>Filter:</label>
    <select v-model="invoicesFilter">
      <option value="all">All</option>
      <option value="open">Open</option>
      <option value="sent">Sent</option>
      <option value="paid">Paid</option>
      <option value="partially-paid">Partially Paid</option>
      <option value="cancelled">Cancelled</option>
    </select>  
  </div>
  <div class="select-wrap">
    <label>Direction:</label>
    <select v-model="invoicesDirection">
      <option value="asc">Ascending</option>
      <option value="desc">Descending</option>
    </select>  
  </div>
  <div class="select-wrap">
    <label>Order:</label>
    <select v-model="invoicesOrder">
      <option value="details.issueDate">Issue Date</option>
      <option value="details.dueDate">Due Date</option>
      <option value="prices.total">Amount Due</option>
    </select>  
  </div>
  <div class="select-wrap">
    <label>Type:</label>
    <select v-model="invoicesType">
      <option value="all">All</option>
      <option :value="false">Invoices</option>
      <option :value="true">Estimates</option>
    </select>  
  </div>
  <table>
    <thead>
      <th class="number">Number</th>
      <th class="client">Client</th>
      <th class="booking">Booking</th>
      <th class="date">Issue Date</th>
      <th class="date">Due Date</th>
      <th>Total Due</th>
      <th class="number">Estimate</th>
      <th class="status">Status</th>
    </thead>
    <tbody v-if="invoices"> 
      <TableRow mode="create" v-if="!clientId && !bookingId" />
      <TableRow v-for="invoice in invoices" :key="invoice.id" :invoice="invoice" />
    </tbody>
  </table>
  <div v-if="isLoading" class="loading">Fetching invoices... ⏳</div>
  <div v-if="!isLoading && invoices && invoices.length && isEmpty" class="success">That's all for now 👍</div>
  <div v-if="!isLoading && !error && (!invoices || !invoices.length)" class="error">No Invoices/Estimates match selected criteria 🤷</div>
  <div v-if="!isLoading && error" class="error">{{ error }}</div>
  <div v-if="invoices && invoices.length && !isEmpty" class="load-more">
    <button @click="() => getDocuments(true)">Load More 👇</button>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import useFirestore from '@/composables/useFirestore'
import TableRow from './TableRow.vue'

export default {
  props: ['bookingId', 'clientId'],
  components: { TableRow },
  setup({ bookingId, clientId }) {
    const router = useRouter()
    const { documents: invoices, isEmpty, error, isLoading, listenDocuments } = useFirestore()
    const invoicesFilter = ref('all')
    const invoicesDirection = ref('desc')
    const invoicesOrder = ref('details.issueDate')
    const invoicesType = ref('all')

    const getDocuments = (attach) => {
      listenDocuments(
        'invoices',
        null,
        null,
        attach,
        [invoicesOrder.value, invoicesDirection.value],
        !bookingId ? null : ['booking.id', '==', bookingId],
        !clientId ? null : ['client.id', '==', clientId],
        invoicesFilter.value === 'all' ? null : ['details.status', '==', invoicesFilter.value],
        invoicesType.value === 'all' ? null : ['details.isEstimate', '==', invoicesType.value],
      )
    }

    getDocuments()

    watch(invoicesFilter, () => getDocuments())

    watch(invoicesDirection, () => getDocuments())

    watch(invoicesOrder, () => getDocuments())

    watch(invoicesType, () => getDocuments())

    return { invoices, isEmpty, error, isLoading, invoicesOrder, invoicesDirection,
      invoicesFilter, invoicesType, getDocuments }
  }
}
</script>

<style>
  th.number, td.number {
    max-width: 50px;
  }
  th.client, td.client, th.booking, td.booking {
    max-width: 90px;
  }
  th.date, td.date {
    max-width: 70px;
  }
  th.status, td.status {
    max-width: 60px;
  }
  td.status select {
    float: unset;
    margin: 0 auto;
  }
</style>